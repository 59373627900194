import React from "react"

import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Typography from "@material-ui/core/Typography"
import NavigateNextIcon from "@material-ui/icons/NavigateNext"

import { makeStyles } from "@material-ui/core/styles"
import Link from "../general/Link"
import { useTranslation } from "gatsby-plugin-react-i18next"

const useStyles = makeStyles(theme => ({
  breadcrumbsWrapper: {
    padding: theme.spacing(2),
    maxWidth: 1080 + theme.spacing(2),
    margin: "0 auto",
  },
  breadcrumbsRoot: {
    display: "flex",
    color: "#FFC400",
  },
  gutterTop: {
    marginTop: 76,
  },
  center: {
    justifyContent: "center",
  },
  separator: {
    color: "#FFC400",
  },
  breadcrumbLink: {
    fontWeight: 600,
    textTransform: "uppercase",
  },
}))

export default ({ center, gutterTop, category, homeLink }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.breadcrumbsWrapper}>
      <Breadcrumbs
        classes={{
          root: `${classes.breadcrumbsRoot} ${center && classes.center} ${
            gutterTop && classes.gutterTop
          }`,
        }}
        separator={<NavigateNextIcon fontSize="small" color="inherit" />}
        aria-label="breadcrumb"
      >
        <Link
          classes={{
            root: classes.linkRoot,
          }}
          color="inherit"
          href={homeLink || "/blog"}
        >
          <Typography
            className={classes.breadcrumbLink}
            variant="body1"
            color="inherit"
          >
            {t("common.home")}
          </Typography>
        </Link>
        <Link
          classes={{
            root: classes.linkRoot,
          }}
          color="inherit"
          href={(homeLink || "/blog/") + category.seo.slug}
        >
          <Typography
            className={classes.breadcrumbLink}
            variant="body1"
            color="inherit"
          >
            {category.name}
          </Typography>
        </Link>
      </Breadcrumbs>
    </div>
  )
}
