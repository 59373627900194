import React from "react"

import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import ArticlesRow from "../components/general/articlesRow"
import JoinBanner from "../components/general/joinBanner"
import NewsletterBanner from "../components/general/newsletterBanner"
import Tabs from "../components/blog/tabs"
import Breadcrumbs from "../components/blog/breadcrumbs"
import useStyles from "./category.style"
import { chunkArray } from "../helpers"
import SEO from "../components/seo"

export const query = graphql`
  query($id: String, $language: String!) {
    landingData: contentfulLanding(node_locale: { eq: $language }) {
      blogTitle
      blogDescription
      joinBannerDescription
      joinBannerTitle
      bannerImage {
        title
        file {
          url
        }
      }
    }
    category: contentfulSubcategory(id: { eq: $id }) {
      name
      category {
        name
      }
      seo {
        slug
        title
        description
      }
      blog_post {
        seo {
          slug
        }
        author {
          name
        }
        coverPicture {
          file {
            url
          }
        }
        title
        createdAt(fromNow: true, locale: $language)
        content {
          json
        }
      }
    }
  }
`

export default function Category({ data }) {
  const { category, landingData } = data
  const classes = useStyles()

  const blogPostsInChunks = chunkArray(category.blog_post, 3)

  return (
    <Layout>
      <SEO title={category.seo.title} description={category.seo.description} />
      <Tabs selected={category.name} categoryName={category.category.name} />
      <Breadcrumbs
        homeLink={category.category.name === "Blog" ? "/blog/" : "/"}
        category={category}
      />
      <div className={classes.categoryHeader}>
        <Typography variant="h4" color="initial" align="left">
          {category.name}
        </Typography>
        <Typography
          className={classes.headerSubtitle}
          variant="button"
          component="p"
          color="initial"
          align="left"
        >
          {category.description}
        </Typography>
      </div>
      {blogPostsInChunks.map((postsChunk, index) => {
        return (
          <>
            {index === Math.round(blogPostsInChunks.length / 2) - 1 && (
              <JoinBanner
                gutterBottom
                title={landingData.joinBannerTitle}
                description={landingData.joinBannerDescription}
                image={landingData.bannerImage}
              />
            )}
            {index === blogPostsInChunks.length - 1 && (
              <NewsletterBanner gutterBottom />
            )}
            <ArticlesRow
              data={postsChunk
                .sort((a, b) => a.hours < b.hours)
                .slice(0, 3)
                .map(post => ({
                  ...post,
                  category,
                  href:
                    category.category.name === "Blog"
                      ? `/blog/${category.seo.slug}/${post.seo.slug}`
                      : `/${category.seo.slug}/${post.seo.slug}`,
                }))}
            />
          </>
        )
      })}
    </Layout>
  )
}
